import React from "react";

import { InfoListBlock as Value, _Item } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

type Props = React.PropsWithChildren<{
    /**
     * Streamfield Block value
     */
    value: Value;
}>;

export const InfoListBlock = (props: Props) => {
    if (!props.value.items) {
        return null;
    }
    return (
        <section className={styles.wrapper}>
            <h2 className={styles.title}>{props.value.title}</h2>
            <StreamField
                components={{
                    item: ({ value }: { value: _Item }) => (
                        <div className={styles.item}>
                            {value.image && (
                                <ImageChooserBlock
                                    attrs={{ className: styles.image }}
                                    value={value.image}
                                />
                            )}
                            {value.content && (
                                <RichTextBlock
                                    className={concatClassNames([
                                        styles.content,
                                        "rich-text",
                                    ])}
                                    value={value.content}
                                />
                            )}
                        </div>
                    ),
                }}
                value={props.value.items}
                tagName="div"
            />
        </section>
    );
};
