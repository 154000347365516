import React from "react";

import {
    Svg as LabUISvg,
    Props,
    SvgElement,
    svgNames as labuiSvgNames,
} from "@thelabnyc/thelabui/src/components/Svg";

/**
 * thelab-ui provides some icons that might be useful, but you'll
 * definitely need project-specific icons. Put the names of those
 * icons here.
 */
export const svgNames = [
    "arrow-up",
    "arrow-right",
    "arrow-left",
    "arrow-down",
    "caret-up",
    "caret-right",
    "caret-left",
    "caret-down",
    "check",
    "close",
    "download",
    "external",
    "minus",
    "pause",
    "play",
    "play-fill",
    "plus",
] as const;

/** If you wish to exclude thelab-ui icons entirely, add their names here. */
const labuiBlacklist = [""];
const labuiSvgNamesFiltered = labuiSvgNames.filter(
    (name) => !labuiBlacklist.includes(name),
);

/**
 * Project-specific SVGs plus SVGs from thelab-ui.
 *
 * This deduplicates, so if you put something into svgNames that's in
 * labuiSvgNames, it'll only show up once. This is useful if you want
 * to override something from thelab-ui.
 */
export const allSvgNames = [
    ...new Set([...labuiSvgNamesFiltered, ...svgNames].sort()),
] as const;

export type SVGName = (typeof allSvgNames)[number];

/**
 * This repo's solution for using icon-like SVGs. "Icon-like" means that the SVG
 * is a relatively small and simple graphic. Most, if not all of our icons are
 * monochromatic, their fills are modified with CSS, and they might be reused in
 * different contexts around the site.
 *
 * This is building around a lab-ui base Svg component, which means that some of
 * these icons are not specific to this repository and might not be what we want
 * to use. They can be overridden if necessary.
 *
 * To add an SVG:
 * - Add a name to `svgNames`
 * - Make the SVG TSX-friendly. https://react-svgr.com/playground/?typescript=true
 *   can help with this
 * - Add a case with your name and your element, replacing `<svg>` with `<SvgElement>`.
 *   You should only need `{...props}` and the `viewBox` attribute; height/width and
 *   fill color can be set with CSS
 *
 * You can hardcode fill colors, but you'll need to be very sure that the color will
 * not change. Typically the logo is a place where you'd do this.
 *
 * Some images might be in the SVG format but not fit this mold, and can be treated as
 * static images instead (placed in the static folder and loaded with `<img>`).
 */
export const Svg = ({ name, ...props }: Props<SVGName>) => {
    switch (name) {
        case "arrow": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="m15.96 8-3.72 3.72-.71-.71 2.51-2.51H0v-1h14.04l-2.51-2.51.71-.71L15.96 8Z" />
                </SvgElement>
            );
        }
        case "arrow-up": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M11.01 4.43 8.5 1.92v14.04h-1V1.91L4.99 4.42l-.71-.71L8 0l3.72 3.72-.71.71Z" />
                </SvgElement>
            );
        }
        case "arrow-right": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="m15.96 8-3.72 3.72-.71-.71 2.51-2.51H0v-1h14.04l-2.51-2.51.71-.71L15.96 8Z" />
                </SvgElement>
            );
        }
        case "arrow-down": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M11.72 12.24 8 15.96l-3.72-3.72.71-.71 2.51 2.51V0h1v14.04l2.51-2.51.71.71Z" />
                </SvgElement>
            );
        }
        case "arrow-left": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M16 8.5H1.96l2.51 2.51-.71.71L.04 8l3.72-3.72.71.71L1.96 7.5H16v1Z" />
                </SvgElement>
            );
        }
        case "burger": {
            return (
                <SvgElement {...props} viewBox="0 0 23.3 16.371">
                    <g
                        id="Group_584"
                        data-name="Group 584"
                        transform="translate(-29.35 -33.279)"
                    >
                        <line
                            id="Line_46"
                            data-name="Line 46"
                            x2="21"
                            transform="translate(30.5 34.429)"
                            fill="none"
                            stroke="#2e6194"
                            strokeLinecap="round"
                            strokeWidth="2.3"
                        />
                        <line
                            id="Line_47"
                            data-name="Line 47"
                            x2="21"
                            transform="translate(30.5 41.464)"
                            fill="none"
                            stroke="#2e6194"
                            strokeLinecap="round"
                            strokeWidth="2.3"
                        />
                        <line
                            id="Line_48"
                            data-name="Line 48"
                            x2="21"
                            transform="translate(30.5 48.5)"
                            fill="none"
                            stroke="#2e6194"
                            strokeLinecap="round"
                            strokeWidth="2.3"
                        />
                    </g>
                </SvgElement>
            );
        }
        case "caret": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="m6.52 14.01-.71-.71 5.3-5.3-5.29-5.3.71-.71L12.54 8l-6.01 6.01Z" />
                </SvgElement>
            );
        }
        case "caret-up": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M13.3 11.18 8 5.88l-5.3 5.3-.71-.71L8 4.46l6.01 6.01-.71.71Z" />
                </SvgElement>
            );
        }
        case "caret-right": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="m6.52 14.01-.71-.71 5.3-5.3-5.29-5.3.71-.71L12.54 8l-6.01 6.01Z" />
                </SvgElement>
            );
        }
        case "caret-down": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M8 11.54 1.99 5.52l.71-.71 5.3 5.3 5.3-5.3.71.71L8 11.53Z" />
                </SvgElement>
            );
        }
        case "caret-left": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M9.48 14.01 3.46 8l6.02-6.01.71.71L4.89 8l5.3 5.3-.71.71Z" />
                </SvgElement>
            );
        }
        case "check": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="m14.36 4.11-8.83 8.84-3.89-3.89.71-.71 3.18 3.18 8.13-8.13.71.71Z" />
                </SvgElement>
            );
        }
        case "close": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="m8.71 8 6.65 6.65-.71.71L8 8.71l-6.65 6.64-.71-.71 6.65-6.65L.65 1.35l.71-.71 6.65 6.65L14.65.65l.71.71-6.65 6.65Z" />
                </SvgElement>
            );
        }
        case "download": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M15.33 15.17v1H.67v-1h14.67Zm-3.61-4.79-.71-.71-2.51 2.51V0h-1v12.19L4.99 9.68l-.71.71L8 14.11l3.72-3.72Z" />
                </SvgElement>
            );
        }
        case "external": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M13.89 2.11v5.26h-1V3.82l-9.93 9.93-.71-.71 9.94-9.93H8.63v-1h5.26Z" />
                </SvgElement>
            );
        }
        case "minus": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M16 7.5v1H0v-1h16Z" />
                </SvgElement>
            );
        }
        case "pause": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M6 2H4v12h2V2Zm6 0h-2v12h2V2Z" />
                </SvgElement>
            );
        }
        case "play": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M3.54.67v14.67l11.33-7.33L3.54.67Zm1 1.83L13.03 8l-8.49 5.5v-11Z" />
                </SvgElement>
            );
        }
        case "play-fill": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M14.67 8 3.33 15.33V.67L14.66 8Z" />
                </SvgElement>
            );
        }
        case "plus": {
            return (
                <SvgElement {...props} viewBox="0 0 16 16">
                    <path d="M16 8.5H8.5V16h-1V8.5H0v-1h7.5V0h1v7.5H16v1Z" />
                </SvgElement>
            );
        }
        default:
            return <LabUISvg name={name} {...props}></LabUISvg>;
    }
};
