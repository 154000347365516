import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import trophyImg from "../../../static/img/hardcoded/jdpower-2023--triple.png";

import styles from "./JDPower.module.scss";

export const JDPower = (props: { visibility: string }) => (
    <section className={concatClassNames([props.visibility, styles.wrapper])}>
        <img className={styles.img} src={trophyImg} alt="" />
        <div>
            <h3 className={styles.title}>
                #1 in Customer Satisfaction with Mattresses Purchased Online
            </h3>
            <p className={styles.copy}>
                — Awarded by J.D. Power in 2023, 2022 &amp; 2021<sup>+</sup>
            </p>
        </div>
    </section>
);
