import React from "react";

import { HTMLAdBarBlock as Value, _LinkImage } from "@reactivated";

import { EmbedBlock } from "@thelabnyc/thelabui/src/components/EmbedBlock";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import {
    concatClassNames,
    getStyleVariantClass,
} from "@thelabnyc/thelabui/src/utils/styles";

import spacingStyles from "../../styles/spacing.module.scss";
import styles from "./index.module.scss";

interface Props {
    value: Value;
}

const LinkImage = (props: { value: _LinkImage }) => {
    if (!props.value.image || !props.value.link) {
        return null;
    } else {
        return (
            <a
                href={props.value.link.url ?? undefined}
                title={props.value.link.display_name ?? undefined}
            >
                <img
                    src={props.value.image.url}
                    alt={props.value.image.title}
                />
            </a>
        );
    }
};

export const HTMLAdBarBlock = (props: Props) => {
    const topSpacingStyleVariant = getStyleVariantClass(
        spacingStyles,
        props.value.spacing?.top_spacing,
    );
    const topInnerSpacingStyleVariant = getStyleVariantClass(
        spacingStyles,
        props.value.spacing?.top_inner_spacing,
    );
    const bottomSpacingStyleVariant = getStyleVariantClass(
        spacingStyles,
        props.value.spacing?.bottom_spacing,
    );
    const bottomInnerSpacingStyleVariant = getStyleVariantClass(
        spacingStyles,
        props.value.spacing?.bottom_inner_spacing,
    );
    return (
        <section
            className={concatClassNames([
                styles.root,
                topSpacingStyleVariant,
                topInnerSpacingStyleVariant,
                bottomSpacingStyleVariant,
                bottomInnerSpacingStyleVariant,
            ])}
        >
            {props.value.content && (
                <StreamField
                    components={{
                        image_link: (block) => <LinkImage {...block} />,
                        raw_HTML: EmbedBlock,
                    }}
                    value={props.value.content}
                />
            )}
        </section>
    );
};
