import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import signatureImage from "../../../static/img/hardcoded/ceo-sign.png";

import styles from "./CEOMessage.module.scss";

export const CEOMessage = (props: { visibility: string }) => (
    <section className={concatClassNames([props.visibility, styles.wrapper])}>
        <h2 className={styles.title}>A Message from Our CEO</h2>
        <p className={styles.copy}>
            To our nearly 12,000 employees around the globe, thank you for what
            you do every day to contribute to our continued success.
        </p>
        <figure>
            <img className={styles.signature} src={signatureImage} alt="" />
            <figcaption className={styles.name}>Scott Thompson</figcaption>
        </figure>
    </section>
);
