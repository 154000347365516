import { useCurrentBreakpoint } from "@client/utils";
import {
    ButtonBack,
    ButtonNext,
    CarouselProvider,
    DotGroup,
    Slide,
    Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";

import { GalleryCarouselBlock as Value, _GallerySlide } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import {
    concatClassNames,
    getStyleVariantClass,
} from "@thelabnyc/thelabui/src/utils/styles";

import { AlignedRichTextBlock } from "../AlignedRichTextBlock";
import { Svg } from "../Svg";

import visibilityStyles from "../../styles/visibility.module.scss";
import styles from "./index.module.scss";

type Props = React.PropsWithChildren<{
    /**
     * Streamfield Block value
     */
    value: Value;
}>;

export const GalleryCarouselBlock = (props: Props) => {
    const viewpoint = useCurrentBreakpoint();
    const visibilityStyle =
        getStyleVariantClass(
            visibilityStyles,
            props.value.responsive_visibility?.visibility,
        ) || "";
    if (!props.value.slides) {
        return null;
    }
    return (
        <section className={concatClassNames([visibilityStyle, styles.root])}>
            {props.value.header_content && (
                <AlignedRichTextBlock
                    value={props.value.header_content}
                    className={styles.header}
                />
            )}
            <CarouselProvider
                visibleSlides={1}
                naturalSlideHeight={400}
                naturalSlideWidth={1000}
                totalSlides={props.value.slides.length}
                isIntrinsicHeight={true}
                dragEnabled={true}
                className={styles.carousel}
                infinite={false}
            >
                <div className={styles.wrapper}>
                    {viewpoint.aboveMobile && (
                        <ButtonBack className={styles.button}>
                            <Svg
                                className={styles.buttonIcon}
                                name="caret-left"
                                visuallyHiddenText="Back"
                            />
                        </ButtonBack>
                    )}
                    <Slider>
                        <StreamField
                            components={{
                                slide: (
                                    { value }: { value: _GallerySlide },
                                    i: number,
                                ) => (
                                    <Slide
                                        key={i}
                                        index={i}
                                        innerClassName={styles.slide}
                                    >
                                        {value.image && (
                                            <ImageChooserBlock
                                                value={value.image}
                                            />
                                        )}
                                        {value.content && (
                                            <RichTextBlock
                                                className={"rich-text"}
                                                value={value.content}
                                            />
                                        )}
                                    </Slide>
                                ),
                            }}
                            value={props.value.slides}
                            tagName="div"
                        />
                    </Slider>
                    {viewpoint.aboveMobile && (
                        <ButtonNext className={styles.button}>
                            <Svg
                                className={styles.buttonIcon}
                                name="caret-right"
                                visuallyHiddenText="Next"
                            />
                        </ButtonNext>
                    )}
                </div>
                <DotGroup className={styles.dots} />
            </CarouselProvider>
        </section>
    );
};
