import { useCurrentBreakpoint } from "@client/utils";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import React from "react";

import { HeaderNavigationBodyBlock as Value, _MenuItem } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { Svg } from "../Svg";
import { MenuItem } from "./MenuItem";

import styles from "./index.module.scss";

type Props = React.PropsWithChildren<{
    /**
     * Streamfield Block value
     */
    value: Value;
}>;

export const Navigation = (props: Props) => {
    const [open, setOpen] = React.useState(false);
    const viewport = useCurrentBreakpoint();
    return (
        <>
            {viewport.belowMobile && (
                <Dialog.Root open={open} onOpenChange={setOpen}>
                    <Dialog.Trigger asChild>
                        <button className={styles.menuButton}>
                            <Svg
                                name="burger"
                                className={styles.menuIcon}
                                visuallyHiddenText="Open Navigation"
                            />
                        </button>
                    </Dialog.Trigger>
                    <Dialog.Portal>
                        <Dialog.Content className={styles.modal}>
                            <VisuallyHidden.Root asChild>
                                <Dialog.Title>Navigation</Dialog.Title>
                            </VisuallyHidden.Root>
                            <Dialog.Close asChild>
                                <button className={styles.menuButton}>
                                    <Svg
                                        name="close"
                                        className={styles.closeIcon}
                                        visuallyHiddenText="Close Navigation"
                                    />
                                </button>
                            </Dialog.Close>
                            <StreamField
                                components={{
                                    menu_item: ({
                                        value,
                                    }: {
                                        value: _MenuItem;
                                    }) => <MenuItem {...value} />,
                                }}
                                value={props.value}
                                tagName="nav"
                                attrs={{ className: styles.mobileMenu }}
                            />
                        </Dialog.Content>
                    </Dialog.Portal>
                </Dialog.Root>
            )}
            {viewport.aboveMobile && (
                <StreamField
                    components={{
                        menu_item: ({ value }: { value: _MenuItem }) => (
                            <MenuItem {...value} />
                        ),
                    }}
                    value={props.value}
                    tagName="nav"
                    attrs={{ className: styles.wrapper }}
                />
            )}
        </>
    );
};
