import React from "react";

import { AlignedRichTextSubBlock as Value } from "@reactivated";

import { AlignedRichTextSubBlock as LabUIAlignedRichTextBlock } from "@thelabnyc/thelabui/src/components/AlignedRichTextSubBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

type Props = React.PropsWithChildren<{
    /**
     * Streamfield Block value
     */
    value: Value;
    className?: string;
}>;

export const AlignedRichTextBlock = (props: Props) => {
    return (
        <LabUIAlignedRichTextBlock
            value={props.value}
            attrs={{
                className: concatClassNames([
                    styles.container,
                    "rich-text",
                    props.className,
                ]),
            }}
        >
            {props.children}
        </LabUIAlignedRichTextBlock>
    );
};
