import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";

import { ColumnsCarouselBlock as Value, _CarouselColumn } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import {
    concatClassNames,
    getStyleVariantClass,
} from "@thelabnyc/thelabui/src/utils/styles";

import { useCurrentBreakpoint } from "../../utils";
import { AlignedRichTextBlock } from "../AlignedRichTextBlock";

import visibilityStyles from "../../styles/visibility.module.scss";
import styles from "./index.module.scss";

type Props = React.PropsWithChildren<{
    /**
     * Streamfield Block value
     */
    value: Value;
}>;

export const ColumnsCarouselBlock = (props: Props) => {
    const viewport = useCurrentBreakpoint();
    const visibilityStyle =
        getStyleVariantClass(
            visibilityStyles,
            props.value.responsive_visibility?.visibility,
        ) || "";
    if (!props.value.columns) {
        return null;
    }
    let visibleSlideNum = 3;
    if (viewport.belowTablet) {
        visibleSlideNum = 2;
    }
    if (viewport.belowMobile) {
        visibleSlideNum = 1;
    }
    let showDots = false;
    if (viewport.aboveMobile && props.value.columns.length > 3) {
        showDots = true;
    }
    if (viewport.belowTablet) {
        showDots = true;
    }
    return (
        <section className={concatClassNames([visibilityStyle, styles.root])}>
            {props.value.header_content && (
                <AlignedRichTextBlock
                    className={styles.header}
                    value={props.value.header_content}
                />
            )}
            <CarouselProvider
                visibleSlides={visibleSlideNum}
                naturalSlideHeight={400}
                naturalSlideWidth={225}
                totalSlides={props.value.columns.length}
                isIntrinsicHeight={true}
                dragEnabled={true}
                className={styles.carousel}
                infinite={false}
            >
                <Slider classNameTray={styles.slideWrapper}>
                    <StreamField
                        components={{
                            column: (
                                { value }: { value: _CarouselColumn },
                                i: number,
                            ) => (
                                <Slide
                                    key={i}
                                    index={i}
                                    className={styles.slide}
                                >
                                    {value.content && (
                                        <RichTextBlock
                                            className={concatClassNames([
                                                styles.columnContent,
                                                "rich-text",
                                            ])}
                                            value={value.content}
                                        />
                                    )}
                                    {value.image && (
                                        <ImageChooserBlock
                                            // attrs={{ className: styles.image }}
                                            value={value.image}
                                        />
                                    )}
                                </Slide>
                            ),
                        }}
                        value={props.value.columns}
                        tagName={React.Fragment}
                        // attrs={{ className: styles.wrapper }}
                    />
                </Slider>
                {showDots && (
                    <DotGroup
                        showAsSelectedForCurrentSlideOnly
                        className={styles.dots}
                    />
                )}
            </CarouselProvider>
        </section>
    );
};
