import React from "react";
import { t } from "ttag";

import { FooterContentBlock as Value } from "@reactivated";

// import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { AlignedRichTextSubBlock } from "@thelabnyc/thelabui/src/components/AlignedRichTextSubBlock";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const Footer = (props: Props) => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <StreamField
                components={{
                    footer_content: ({ value }) => (
                        <>
                            <p className={styles.fineprint}>
                                &copy; {currentYear} {value.copyright}{" "}
                                {value.privacy_policy && (
                                    <a
                                        href={value.privacy_policy}
                                        className={styles.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t`Privacy Policy`}
                                    </a>
                                )}{" "}
                                |{" "}
                                {value.cali_privacy_policy && (
                                    <a
                                        href={value.cali_privacy_policy}
                                        className={styles.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t`California Privacy Policy`}
                                    </a>
                                )}{" "}
                                |{" "}
                                {value.terms && (
                                    <a
                                        href={value.terms}
                                        className={styles.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t`Terms of Use`}
                                    </a>
                                )}{" "}
                                | {/* OneTrust Cookies Settings button start */}
                                <span className={styles.oneTrustButton}>
                                    <button
                                        id="ot-sdk-btn"
                                        className="ot-sdk-show-settings"
                                    >
                                        {t`Cookie Settings`}
                                    </button>
                                </span>
                                {/* OneTrust Cookies Settings button end */}
                            </p>
                            {value.disclaimer && (
                                <AlignedRichTextSubBlock
                                    attrs={{ className: "rich-text" }}
                                    value={value.disclaimer}
                                />
                            )}
                        </>
                    ),
                }}
                value={props.value}
            />
        </>
    );
};
