import { useCurrentBreakpoint } from "@client/utils";
import * as HoverCard from "@radix-ui/react-hover-card";
import React from "react";

import { _MenuItem } from "@reactivated";

import styles from "./MenuItem.module.scss";

export const MenuItem = (props: _MenuItem) => {
    const viewport = useCurrentBreakpoint();
    if (!props.menu_link) {
        return null;
    }
    if (props.sub_menu_items && props.sub_menu_items.length > 0) {
        return (
            <>
                {viewport.belowMobile && (
                    <>
                        <a
                            className={styles.mobileItem}
                            href={props.menu_link.url ?? undefined}
                        >
                            {props.menu_link.display_name ??
                                props.menu_link.url}
                        </a>
                        {props.sub_menu_items.map((item, i) => (
                            <a
                                key={i}
                                className={styles.mobileItem}
                                href={item.value.url ?? undefined}
                            >
                                {item.value.display_name ?? item.value.url}
                            </a>
                        ))}
                    </>
                )}
                {viewport.aboveMobile && (
                    <HoverCard.Root openDelay={0}>
                        <HoverCard.Trigger
                            className={
                                viewport.belowMobile
                                    ? styles.mobileItem
                                    : styles.item
                            }
                            href={props.menu_link.url ?? undefined}
                        >
                            {props.menu_link.display_name ??
                                props.menu_link.url}
                        </HoverCard.Trigger>
                        <HoverCard.Content
                            align="start"
                            className={styles.subMenu}
                        >
                            {props.sub_menu_items.map((item, i) => (
                                <a
                                    key={i}
                                    className={styles.subItem}
                                    href={item.value.url ?? undefined}
                                >
                                    {item.value.display_name ?? item.value.url}
                                </a>
                            ))}
                        </HoverCard.Content>
                    </HoverCard.Root>
                )}
            </>
        );
    } else {
        return (
            <a
                className={
                    viewport.belowMobile ? styles.mobileItem : styles.item
                }
                href={props.menu_link.url ?? undefined}
            >
                {props.menu_link.display_name ?? props.menu_link.url}
            </a>
        );
    }
};
