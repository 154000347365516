import "@client/styles/global.scss";
import React from "react";
import { Helmet } from "react-helmet-async";

import { Context } from "@reactivated";

import { MetaTagsBlock } from "@thelabnyc/thelabui/src/components/MetaTagsBlock";

import { Footer } from "../Footer";
import { Navigation } from "../Navigation";
import { StockTicker } from "../StockTicker";

import styles from "./index.module.scss";

interface Props {
    title: string;
    seo_title?: string;
    search_description?: string;
    robots?: string;
    rootClassName?: string;
    children?: React.ReactNode;
}

export const PageSkeleton = (props: Props) => {
    const context = React.useContext(Context);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.seo_title || props.title}</title>
                {props.search_description && (
                    <meta
                        name="description"
                        content={props.search_description}
                    />
                )}
                {props.robots && <meta name="robots" content={props.robots} />}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link rel="icon" href={context.core_assets.favicon} />
                {MetaTagsBlock({
                    tags: context.meta_tags.meta_tags.value,
                    cspNonce: context.request.csp_nonce,
                })}
            </Helmet>
            <header className={styles.header}>
                <div className={styles.container}>
                    <a href="/">
                        <img
                            className={styles.logo}
                            src={context.core_assets.logo}
                            alt="Tempur Sealy"
                        />
                    </a>
                    {context.header_nav && (
                        <Navigation value={context.header_nav.body.value} />
                    )}
                </div>
            </header>
            <main>{props.children}</main>
            <footer className={styles.footer}>
                <div className={styles.container}>
                    {context.stock_quotes.length > 0 && (
                        <StockTicker quote={context.stock_quotes[0]} />
                    )}
                    {context.footer_content && (
                        <Footer value={context.footer_content.content.value} />
                    )}
                </div>
            </footer>
        </>
    );
};
