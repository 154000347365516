import React from "react";

import {
    MediaChooserBlock as MediaChooserBlockValue,
    PromotionBlock as Value,
    _Column,
    _ValueProp,
} from "@reactivated";

import { AlignedRichTextSubBlock } from "@thelabnyc/thelabui/src/components/AlignedRichTextSubBlock";
import { EmbedBlock } from "@thelabnyc/thelabui/src/components/EmbedBlock";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import {
    concatClassNames,
    getStyleVariantClass,
} from "@thelabnyc/thelabui/src/utils/styles";

import backgroundStyles from "../../styles/background-styles.module.scss";
import visibilityStyles from "../../styles/visibility.module.scss";
import columnStyles from "./column-styles.module.scss";
import styles from "./index.module.scss";

interface Props {
    value: Value;
}

const BackgroundMedia = (props: { media: MediaChooserBlockValue }) => (
    <div className={styles.backgroundMedia}>
        <MediaChooserBlock value={props.media} />
    </div>
);

const ValueProps = (props: {
    id?: string;
    type?: "value_props";
    value: {
        type: "value_prop";
        value: _ValueProp;
        id: string;
    }[];
}) => {
    return (
        <>
            {props.value.map((vp, i) => {
                return (
                    <StreamField
                        key={i}
                        components={{
                            icon: (block) => (
                                <ImageChooserBlock
                                    attrs={{ className: styles.valuePropImage }}
                                    {...block}
                                />
                            ),
                            content: RichTextBlock,
                        }}
                        value={vp.value}
                        tagName="div"
                        attrs={{ className: styles.valueProp }}
                    />
                );
            })}
        </>
    );
};

const Column = (props: { id?: string; type?: "column"; value: _Column }) => {
    return (
        <StreamField
            components={{
                image: ({ value }) => (
                    <img
                        className={styles.columnImage}
                        src={value.url}
                        alt={value.title}
                    />
                ),
                aligned_rich_text_block: (block) => (
                    <AlignedRichTextSubBlock
                        attrs={{ className: "rich-text" }}
                        {...block}
                    />
                ),
                raw_HTML: EmbedBlock,
                value_props: (block) => <ValueProps {...block} />,
            }}
            value={props.value}
            tagName="div"
            attrs={{
                className: styles.column,
            }}
        />
    );
};

export const PromotionBlock = (props: Props) => {
    const styleVariant = getStyleVariantClass(
        columnStyles,
        props.value.column_layout,
    );
    const backgroundStyleVariant = getStyleVariantClass(
        backgroundStyles,
        props.value.background_style,
    );
    const visibilityStyle =
        getStyleVariantClass(
            visibilityStyles,
            props.value.responsive_visibility?.visibility,
        ) || "";
    switch (props.value.variant) {
        case "soloBoxLeft":
            return (
                <section
                    className={concatClassNames([
                        styles.root,
                        backgroundStyleVariant,
                        visibilityStyle,
                    ])}
                >
                    {props.value.background_media && (
                        <BackgroundMedia media={props.value.background_media} />
                    )}
                    {props.value.columns && (
                        <div
                            className={concatClassNames([
                                styles.columnWrapper,
                                styles.stackOnMobile,
                            ])}
                        >
                            <StreamField
                                components={{
                                    column: (block) => <Column {...block} />,
                                }}
                                value={props.value.columns}
                                tagName="div"
                                attrs={{
                                    className: styles.soloBoxLeft,
                                }}
                            />
                        </div>
                    )}
                </section>
            );
        case "soloBoxRight":
            return (
                <section
                    className={concatClassNames([
                        styles.root,
                        backgroundStyleVariant,
                        visibilityStyle,
                    ])}
                >
                    {props.value.background_media && (
                        <BackgroundMedia media={props.value.background_media} />
                    )}
                    {props.value.columns && (
                        <div
                            className={concatClassNames([
                                styles.columnWrapper,
                                styles.stackOnMobile,
                            ])}
                        >
                            <StreamField
                                components={{
                                    column: (block) => <Column {...block} />,
                                }}
                                value={props.value.columns}
                                tagName="div"
                                attrs={{
                                    className: styles.soloBoxRight,
                                }}
                            />
                        </div>
                    )}
                </section>
            );
        case "soloTextLeft":
            return (
                <section
                    className={concatClassNames([
                        styles.root,
                        backgroundStyleVariant,
                        visibilityStyle,
                    ])}
                >
                    {props.value.background_media && (
                        <BackgroundMedia media={props.value.background_media} />
                    )}
                    {props.value.columns && (
                        <div className={styles.columnWrapper}>
                            <StreamField
                                components={{
                                    column: (block) => <Column {...block} />,
                                }}
                                value={props.value.columns}
                                tagName="div"
                                attrs={{
                                    className: styles.soloTextLeft,
                                }}
                            />
                        </div>
                    )}
                </section>
            );
        case "fixedIconSize":
            return (
                <section
                    className={concatClassNames([
                        styles.root,
                        backgroundStyleVariant,
                        visibilityStyle,
                        styles.fixedIconSizeWrapper,
                    ])}
                >
                    {props.value.background_media && (
                        <BackgroundMedia media={props.value.background_media} />
                    )}
                    {props.value.header_content && (
                        <AlignedRichTextSubBlock
                            attrs={{ className: "rich-text" }}
                            value={props.value.header_content}
                        />
                    )}
                    {props.value.columns && (
                        <StreamField
                            components={{
                                column: (block) => <Column {...block} />,
                            }}
                            value={props.value.columns}
                            tagName="div"
                            attrs={{
                                className: styles.fixedIconSize,
                            }}
                        />
                    )}
                    {props.value.footer_content && (
                        <AlignedRichTextSubBlock
                            attrs={{ className: "rich-text" }}
                            value={props.value.footer_content}
                        />
                    )}
                </section>
            );
        default:
            return (
                <section
                    className={concatClassNames([
                        styles.root,
                        backgroundStyleVariant,
                        visibilityStyle,
                    ])}
                >
                    {props.value.header_content && (
                        <AlignedRichTextSubBlock
                            attrs={{ className: "rich-text" }}
                            value={props.value.header_content}
                        />
                    )}
                    {props.value.columns && (
                        <StreamField
                            components={{
                                column: (block) => <Column {...block} />,
                            }}
                            value={props.value.columns}
                            tagName="div"
                            attrs={{
                                className: concatClassNames([
                                    styles.columnContainer,
                                    styleVariant,
                                ]),
                            }}
                        />
                    )}
                    {props.value.footer_content && (
                        <AlignedRichTextSubBlock
                            attrs={{ className: "rich-text" }}
                            value={props.value.footer_content}
                        />
                    )}
                </section>
            );
    }
};
