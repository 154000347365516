import React from "react";

import { Toolbox as Value } from "@reactivated";

import { OneTrustPrivacyNoticeBlock } from "@thelabnyc/thelabui/src/components/OneTrustPrivacyNoticeBlock";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";

import { AlignedRichTextBlock } from "../AlignedRichTextBlock";
import { AnchorBlock } from "../Anchor";
import { ColumnsCarouselBlock } from "../ColumnsCarousel";
import { GalleryCarouselBlock } from "../GalleryCarousel";
import { HTMLAdBarBlock } from "../HTMLAdBarBlock";
import { HardcodedBlock } from "../HardcodedBlock";
import { InfoListBlock } from "../InfoListBlock";
import { PromotionBlock } from "../PromotionBlock";

interface Props {
    value: Value;
}

const components: StreamFieldBlockMap<Value> = {
    anchor: AnchorBlock,
    artf: AlignedRichTextBlock,
    columns_carousel: ColumnsCarouselBlock,
    gallery_carousel: GalleryCarouselBlock,
    hardcoded: HardcodedBlock,
    info_list: InfoListBlock,
    promotion: PromotionBlock,
    html_ad_bar: HTMLAdBarBlock,
    onetrustprivacynoticeblock: OneTrustPrivacyNoticeBlock,
};

export const Toolbox = (props: Props) => (
    <StreamField components={components} value={props.value} />
);
