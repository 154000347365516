import React from "react";

import { HardcodedBlock as Value } from "@reactivated";

import { getStyleVariantClass } from "@thelabnyc/thelabui/src/utils/styles";

import { CEOMessage } from "./CEOMessage";
import { JDPower } from "./JDPower";
import { SocialBanner } from "./SocialBanner";

import visibilityStyles from "../../styles/visibility.module.scss";

interface Props {
    value: Value;
}

export const HardcodedBlock = (props: Props) => {
    const visibilityStyle =
        getStyleVariantClass(
            visibilityStyles,
            props.value.responsive_visibility?.visibility,
        ) || "";
    switch (props.value.version) {
        case "ceo-message":
            return <CEOMessage visibility={visibilityStyle} />;
        case "social-banner":
            return <SocialBanner visibility={visibilityStyle} />;
        case "jd-power":
            return <JDPower visibility={visibilityStyle} />;
        default:
            return null;
    }
};
