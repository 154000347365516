import React from "react";

import { ServerCmsModelsStocksStockQuote as StockQuote } from "@reactivated";

import styles from "./index.module.scss";

export const StockTicker = (props: { quote: StockQuote }) => (
    <dl className={styles.wrapper}>
        <div>
            <dt>{props.quote.exchangeShortCode}:</dt>
            <dd>{props.quote.symbol}</dd>
        </div>
        <div>
            <dt className={styles.label}>Open</dt>
            <dd>{props.quote.open}</dd>
        </div>
        <div>
            <dt className={styles.label}>High</dt>
            <dd>{props.quote.dayHigh}</dd>
        </div>
        <div>
            <dt className={styles.label}>Last</dt>
            <dd>{props.quote.lastTrade}</dd>
        </div>
        <div>
            <dt className={styles.label}>Change</dt>
            <dd>{props.quote.changePercent}</dd>
        </div>
        <div>
            <dt className={styles.label}>Volume</dt>
            <dd>{props.quote.volume} (20 minute delay)</dd>
        </div>
    </dl>
);
