import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";

import styles from "./SocialBanner.module.scss";

export const SocialBanner = (props: { visibility: string }) => (
    <section className={concatClassNames([props.visibility, styles.wrapper])}>
        <a
            className={styles.applyButton}
            href="https://recruiting.adp.com/srccar/public/RTI.home?c=1124607&d=CorporateCareerSite"
        >
            Apply Now
        </a>
        <a
            className={styles.button}
            href="https://www.facebook.com/TempurSealyInternational/"
        >
            Our Facebook
            <Svg className={styles.icon} name="facebook" />
        </a>
        <a
            className={styles.button}
            href="https://www.linkedin.com/company/tempur-pedic/"
        >
            Our Linkedin
            <Svg className={styles.icon} name="linkedin" />
        </a>
    </section>
);
